import React from 'react'
import logo from '../img/logo.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook} from '@fortawesome/free-brands-svg-icons'
import {faInstagram} from '@fortawesome/free-brands-svg-icons'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons'
import {faLaptop} from "@fortawesome/free-solid-svg-icons";

const Navbar = () => (
    <header className="navbar navbar-expand navbar-dark flex-column flex-md-row fixed-top bg-dark">
        <div className="container">
            <button className='navbar-toggler' data-target='#collapsingNavbar' data-toggle='collapse' type='button'>
                <span>☰</span>
            </button>
            <div className="navbar-brand">
                <a href="https://www.conity.fr" target="_blank"  rel="noopener noreferrer" className="navbar-item">
                    <figure className="image">
                        <img src={logo}  alt="Logo Villas La Provençale" className="logo"/>
                    </figure>
                </a>
            </div>
            <ul className='nav navbar-nav ml-auto '>
                <li className='nav-item p-2 text-light'>
                    Rejoignez nous sur :
                </li>
                <li className='nav-item p-2'>
                    <a href="https://www.facebook.com/villaslaprovencale/"  target="_blank"  rel="noopener noreferrer"  title="Aimez nous sur Facebook">
                        <FontAwesomeIcon icon={faFacebook}/>
                    </a>
                </li>
                <li className='nav-item p-2'>
                    <a href="https://www.instagram.com/villas_la_provencale/?hl=fr"  target="_blank"  rel="noopener noreferrer"  title="Follow nous sur Twitter">
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>
                </li>
                <li className='nav-item p-2'>
                    <a href="https://fr.linkedin.com/company/villas-la-provencale"  target="_blank"  rel="noopener noreferrer"  title="Abonnez vous à nous sur LinkedIn">
                        <FontAwesomeIcon icon={faLinkedin}/>
                    </a>
                </li>
                <li className='nav-item p-2'>
                    <a href="https://www.villaslaprovencale.com/"  target="_blank"  rel="noopener noreferrer"  title="Visitez notre site internet">
                        <FontAwesomeIcon icon={faLaptop}/>
                    </a>
                </li>
            </ul>
        </div>
    </header>
)

export default Navbar
